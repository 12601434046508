import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import PlayerSearch from "./components/PlayerSearch";
import PlayerStats from "./components/PlayerStats";
import "./App.css";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode");
    setDarkMode(isDarkMode === null ? true : isDarkMode === "true");
  }, []);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
      <div className={`App ${darkMode ? "dark-mode" : ""}`}>
        <header className="App-header">
          <Link to="/" className="header-link">
            <h1>🏓 ping.romain.tech</h1>
          </Link>
          <button className="dark-mode-toggle" onClick={toggleDarkMode}>
            {darkMode ? "🌞" : "🌙"}
          </button>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<PlayerSearch />} />
            <Route path="/player/:id" element={<PlayerStats />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
