import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";

function PlayerSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (term.length >= 2) {
        const response = await fetch(
          `${window._env_.REACT_APP_API_URL}/api/search?nom=${term}`
        );
        const data = await response.json();
        setSearchResults(data);
      } else {
        setSearchResults([]);
      }
    }, 300),
    [setSearchResults]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    const loadFavorites = async () => {
      const favIds = JSON.parse(localStorage.getItem("favorites") || "[]");
      const favPlayers = await Promise.all(
        favIds.map(async (id) => {
          const response = await fetch(
            `${window._env_.REACT_APP_API_URL}/api/player/${id}`
          );
          const data = await response.json();
          return {
            ...data.infoJoueur,
            points: data.infoJoueur.virtual,
          };
        })
      );
      setFavorites(favPlayers);
    };
    loadFavorites();
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="player-search">
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Rechercher un joueur"
      />
      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Résultats de la recherche</h3>
          {searchResults.map((joueur) => (
            <Link key={joueur.licence} to={`/player/${joueur.licence}`}>
              {joueur.nom} {joueur.prenom}
            </Link>
          ))}
        </div>
      )}
      {favorites.length > 0 && (
        <div className="favorite-players">
          <h3>Joueurs favoris</h3>
          {favorites.map((joueur) => (
            <Link
              key={joueur.licence}
              to={`/player/${joueur.licence}`}
              className="favorite-player"
            >
              <span>
                {joueur.nom} {joueur.prenom}
              </span>
              <span className="player-points"> - {joueur.points}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlayerSearch;
