import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function PlayerStats() {
  const { id } = useParams();
  const [playerData, setPlayerData] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const fetchPlayerData = async () => {
      const response = await fetch(`${window._env_.REACT_APP_API_URL}/api/player/${id}`);
      const data = await response.json();
      setPlayerData(data);
    };
    fetchPlayerData();

    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    setIsFavorite(favorites.includes(id));
  }, [id]);

  if (!playerData) return <div className="loading">Chargement...</div>;

  const { infoJoueur, matches, pointsHistory } = playerData;

  const toggleFavorite = () => {
    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    if (isFavorite) {
      const newFavorites = favorites.filter(fav => fav !== id);
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
    } else {
      favorites.push(id);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
    setIsFavorite(!isFavorite);
  };

  const determineMatchResult = (match) => {
    const pointChange = parseFloat(match.ex);
    if (pointChange > 0) return 'win';
    if (pointChange < 0) return 'loss';
    return parseFloat(infoJoueur.point) >= parseFloat(match.p) ? 'win' : 'loss';
  };

  return (
    <div className="player-stats">
      <div className="player-header">
        <h2>{infoJoueur.nom} {infoJoueur.prenom}</h2>
        <button onClick={toggleFavorite} className="favorite-button">
          {isFavorite ? '★' : '☆'}
        </button>
      </div>
      <div className="points-graph">
        <h3>Évolution des points</h3>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={pointsHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{fontSize: 12}} angle={-45} textAnchor="end" height={50} />
            <YAxis domain={['dataMin - 10', 'dataMax + 10']} tick={{fontSize: 12}} />
            <Tooltip />
            <Legend wrapperStyle={{fontSize: 12}} />
            <Line type="monotone" dataKey="points" stroke="#3498db" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="player-info">
        <p>Club : <strong>{infoJoueur.nomclub}</strong></p>
        <p>Points réels : <strong>{infoJoueur.point}</strong></p>
        <p>Points virtuels : <strong>{infoJoueur.virtual}</strong></p>
        <p>Victoires : <span className="wins"><strong>{infoJoueur.victoires}</strong></span></p>
        <p>Défaites : <span className="losses"><strong>{infoJoueur.defaites}</strong></span></p>
      </div>
      {matches.list && matches.list.length > 0 && (
        <div className="matches">
          <h3>Matches</h3>
          {matches.list[0].journees.map((journee, index) => (
            <div key={index} className="journee">
              <div className={`journee-date ${parseFloat(journee.ex) > 0 ? 'wins' : parseFloat(journee.ex) < 0 ? 'losses' : 'neutral'}`}>
                <span>{journee.date}</span>
                <span>{journee.ex}</span>
              </div>
              <div className="match-list">
                {journee.matchs.map((match, matchIndex) => (
                  <div key={matchIndex} className={`match ${determineMatchResult(match)}`}>
                    <Link to={`/player/${match.licence}`}>
                      <span className="match-points">[{match.p}]</span>
                      <span className="match-name">{match.nom}</span>
                      <span className="match-result">{match.ex}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlayerStats;
